<template>
  <cube-scroll ref="scroll" :data="data" :options="options" @pulling-down="firstPage" @pulling-up="nextPage">
    <div v-if="loading" class="mobile-list-loading">
      <cube-loading :size="30"></cube-loading>
      <div class="mobile-list-loading-text">正在加载</div>
    </div>
    <slot v-if="data && data.length > 0"></slot>
    <div v-else-if="loading==false && (data==null || data.length==0)" class="mobile-list-empty">
      <img class="mobile-list-empty-img" src="@/static/images/empty.png" alt />
      <div class="mobile-list-empty-text">暂无记录</div>
    </div>
  </cube-scroll>
</template>

<script>
export default {
  props: {
    data: Array,
    options: Object,
    firstPage: Function,
    nextPage: Function,
    loading: Boolean
  },
  methods: {
    forceUpdate: function (param) {
      this.$refs.scroll.forceUpdate(param)
    }
  }
}
</script>
